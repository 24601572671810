import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['showMoreButton', 'showLessButton', 'faqItem'];
  static values = { maxDisplayedItems: Number };

  connect() {
    new Foundation.Accordion($(this.element).find('.js-faq-accordion__list'));

    this.toggleFaqState('collapse');
  }

  showMore() {
    this.showMoreButtonTarget.classList.add('is-hidden');
    this.showLessButtonTarget.classList.remove('is-hidden');
    this.toggleFaqState('expand');
  }

  showLess() {
    this.showMoreButtonTarget.classList.remove('is-hidden');
    this.showLessButtonTarget.classList.add('is-hidden');
    this.toggleFaqState('collapse');
  }

  toggleFaqState(action) {
    const maxDisplayedItems = this.maxDisplayedItemsValue;

    this.faqItemTargets.forEach((item, index) => {
      if (index >= maxDisplayedItems) {
        item.classList.toggle('is-hidden', action === 'collapse');
      }
    });
  }
}
