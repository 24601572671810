/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '../helpers/lazy_load_helper.js';
import '../helpers/element_helpers.js';

import '../application/layouts/banners.coffee';
import '../application/layouts/close_banner_button.js';
import '../application/layouts/page_footer.js';
import '../application/layouts/page_header.js';
import '../application/layouts/teacher_feedback_popup.js';
import '../application/layouts/school_directory_expired_license_popup.js';

import '../application/layouts/page_header/subjects_menu.js';
import '../application/layouts/page_header/teacher_box.js';
import '../application/layouts/page_header/white_nav_bar.js';

import '../application/layouts/profile_settings/profile.coffee';
import '../application/layouts/profile_settings/continue_learning_popup.coffee';
import '../application/learning_groups/student_feedback_popup.coffee';

import '../application/layouts/homework_chat.coffee';
import '../application/layouts/automated_chat_message.coffee';

import '../application/school_directory/teacher_school_selection_popup.js';
import '../application/account/kids_nps_survey.coffee';

import '../application/application.sass';
import '../application/videos/trial_flow.coffee';

import 'controllers';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
import ReactOnRails from 'react-on-rails';
import UserNotifications from '../components/UserNotifications';
import SofabuddyChatBot from '../components/SofabuddyChatBot';
import Dropdown from '@UIComponents/atoms/Dropdown/Dropdown';
import FeaturedCarousel from '@UIComponents/organisms/FeaturedCarousel/FeaturedCarousel';
import ProgressBar from '@UIComponents/molecules/ProgressBar/ProgressBar';
import SpeechBubble from '@UIComponents/molecules/SpeechBubble/SpeechBubble';
import { GrundschriftTypography } from '@UIComponents/atoms/Typography/Typography';

ReactOnRails.register({
  UserNotifications,
  SofabuddyChatBot,
  Dropdown,
  FeaturedCarousel,
  ProgressBar,
  SpeechBubble,
  GrundschriftTypography
});
