/* eslint-disable no-console */
/* jshint esversion: 8 */
import React, { useState, useMemo, useEffect } from 'react';
import ChatBot, {
  ChatBotProvider,
  useMessages,
  useStyles,
  useSettings,
  usePaths
} from 'react-chatbotify';
import * as Sentry from '@sentry/browser';
import './sofabuddy_chatbot/styles.sass';
import makeRequest from '../lib/fetchService';
import './translations/componentsInit';
import closeChatIcon from '/app/javascript/icons/svgs/minus-white.svg';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './sofabuddy_chatbot/ErrorMessage';
import WarningMessage from './sofabuddy_chatbot/WarningMessage';
import IntroMessage from './sofabuddy_chatbot/IntroMessage';
import WelcomeMessage from './sofabuddy_chatbot/WelcomeMessage';
import { chatbotSettings } from './sofabuddy_chatbot/sofabuddySettings';
import FeedbackMessage from './sofabuddy_chatbot/FeedbackMessage';
import OpenAiWrapper from './OpenAiWrapper';
import { amplitude_tracking } from 'helpers/amplitudeTracking';
import { triggerSurvey } from './sofabuddy_chatbot/surveys';

const defaultStyles = {
  chatWindowStyle: {
    left: '1rem',
    height: '48.75rem'
  },
  chatButtonStyle: {
    left: '1rem'
  },
  tooltipStyle: {
    left: '7rem',
    width: 'fit-content',
    zIndex: '3',
    backgroundColor: '#4b33c4',
    fontWeight: '400',
    fontSize: '.875rem',
    borderRadius: '.75rem'
  },
  headerStyle: {
    height: '4rem',
    background: '#7866d3',
    fontWeight: 'bold',
    fontSize: '1.375rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chatHistoryButtonHoveredStyle: {
    background: '#E6E8E5',
    borderRadius: '.25rem',
    color: '#3C3C3C',
    fontSize: '1rem',
    border: '1px solid #000'
  },
  botBubbleStyle: {
    fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
    color: '#333',
    backgroundColor: '#E9E6F8'
  },
  userBubbleStyle: {
    color: '#333',
    backgroundColor: '#FDEB99'
  },
  chatInputAreaStyle: {
    border: '1px solid #E1E1E1',
    borderRadius: '.5rem',
    boxSizing: 'border-box'
  },
  chatInputAreaFocusedStyle: {
    boxShadow: '#7866d3 0 0 0.3125rem'
  },
  sendButtonStyle: {
    backgroundColor: '#7866d3',
    height: '2.5rem'
  },
  sendButtonHoveredStyle: {
    backgroundColor: '#4b33c4',
    height: '2.5rem'
  },
  sendButtonDisabledStyle: {
    backgroundColor: '#a599e1',
    height: '2.5rem'
  },
  chatInputContainerStyle: {
    display: 'none'
  },
  footerStyle: {
    display: 'none'
  },
  chatHistoryButtonStyle: {
    display: 'none'
  }
};

const visibleInputFieldStyles = {
  chatInputContainerStyle: {
    display: 'flex',
    padding: '1rem 1.5rem',
    borderTop: '1px solid #E1E1E1'
  },
  chatHistoryButtonStyle: {
    display: 'inline-flex',
    background: '#E6E8E5',
    borderRadius: '.25rem',
    color: '#3C3C3C',
    fontSize: '1rem'
  },
  footerStyle: {
    display: 'flex',
    background: '#F8F8F8',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
    fontSize: '0.875rem',
    padding: '0.75rem',
    borderTop: '1px solid #E1E1E1'
  }
};

const SofabuddyChatBotWrapper = ({
  sofabuddyCookieExists,
  sofabuddyThreadId,
  currentProfileId,
  dailyRemainingSofabuddyMessages,
  showSofabuddySurvey,
  sofabuddySurveyClicked
}) => {
  Sentry.init({
    dsn:
      'https://3c7dcb320f67c6247aefb17f41c03901@o469049.ingest.us.sentry.io/4507823094693888',
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV === 'production',
    release: 'sofabuddy'
  });

  if (Sofatutor.consentGivenForCategory('analytics')) {
    Sentry.setUser({ id: window.Sofatutor.user.id });
  }

  const { t } = useTranslation();
  const openAiKey = window.Sofatutor.Sofabuddy.openAi.apiKey;
  const baseURL = window.Sofatutor.Sofabuddy.openAi.baseURL;
  const { setMessages } = useMessages();
  const { setStyles } = useStyles(defaultStyles);
  const { setSettings } = useSettings();
  const [thread, setThread] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [, setChatIsOpen] = useState(false);
  const [usageCounter, setUsageCounter] = useState(
    dailyRemainingSofabuddyMessages
  );
  const assistantId = 'asst_RGtBOYyGoRHTpPD7KWHNIpVo';

  const { goToPath } = usePaths();
  const openAiWrapper = useMemo(() => {
    if (usageCounter <= 0) {
      return null;
    }
    return new OpenAiWrapper(openAiKey, baseURL);
  }, [openAiKey, baseURL, usageCounter]);

  const maskKey = key => {
    if (key && key.length > 6) {
      return `${key.slice(0, 3)}...${key.slice(-3)}`;
    }
    return 'Invalid key';
  };

  useEffect(() => {
    cleanChatHistory(currentProfileId, ['feedback', 'error', 'warning']);
  }, [openAiKey, baseURL]);

  useEffect(() => {
    const handleLoadChatHistory = () => {
      if (!sofabuddyThreadId) {
        amplitude_tracking('sofabuddy_past_history_clicked');
      }
    };

    window.addEventListener('rcb-load-chat-history', handleLoadChatHistory);
    return () => {
      window.removeEventListener(
        'rcb-load-chat-history',
        handleLoadChatHistory
      );
    };
  }, []);

  useEffect(() => {
    const handleUserSubmitText = () => {
      if (!messageSent) {
        amplitude_tracking('sofabuddy_chat_started');
        setMessageSent(true);
      }
    };

    window.addEventListener('rcb-user-submit-text', handleUserSubmitText);

    return () => {
      window.removeEventListener('rcb-user-submit-text', handleUserSubmitText);
    };
  }, [messageSent]);

  useEffect(() => {
    const handleToggleChatWindow = () => {
      setChatIsOpen(prevChatIsOpen => {
        if (prevChatIsOpen) {
          amplitude_tracking('sofabuddy_chat_ended');
        } else {
          amplitude_tracking('sofabuddy_clicked');
        }

        return !prevChatIsOpen;
      });
    };

    window.addEventListener('rcb-toggle-chat-window', handleToggleChatWindow);

    return () => {
      window.removeEventListener(
        'rcb-toggle-chat-window',
        handleToggleChatWindow
      );
    };
  }, []);

  const cleanChatHistory = (profileId, types) => {
    const history = localStorage.getItem(profileId);
    if (history) {
      const data = JSON.parse(history);
      if (Array.isArray(data)) {
        const filteredData = data.filter(
          entry => !types.includes(entry.sender)
        );
        localStorage.setItem(profileId, JSON.stringify(filteredData));
      }
    }
  };

  const updateStyles = newStyles => {
    setStyles(prevStyles => ({
      ...prevStyles,
      ...newStyles
    }));
  };

  const disableChat = () => {
    updateStyles(visibleInputFieldStyles);
    amplitude_tracking('sofabuddy_daily_message_limit_reached');
    goToPath('end');
  };

  const resumeChat = () => {
    setMessages(prev => prev.filter(msg => msg.sender != 'system'));
    setSettings(prevSettings => ({
      ...prevSettings,
      chatHistory: {
        ...prevSettings.chatHistory,
        disabled: false
      }
    }));
    updateStyles(visibleInputFieldStyles);
    goToPath('welcome');
  };

  const initializeChat = () => {
    if (usageCounter <= 0) {
      disableChat();
    } else if (!sofabuddyCookieExists) {
      goToPath('intro');
    } else {
      resumeChat();
      sofabuddyCookieExists && triggerSurvey();
    }
  };

  const setCookie = async threadId => {
    const apiUrl = '/sofabuddy_chat_bot_used';
    await makeRequest(apiUrl, 'PATCH', { thread_id: threadId });
  };

  const retrieveOrCreateThread = async params => {
    if (window.Sofatutor.env === 'test') {
      // For cypress tests, to create a fake thread (avoid using the OpenAI API)
      return { id: 'test_thread_id' };
    }

    try {
      if (sofabuddyThreadId) {
        return await openAiWrapper.executeWithRetry(
          async client => await client.beta.threads.retrieve(sofabuddyThreadId)
        );
      } else {
        const newThread = await openAiWrapper.executeWithRetry(
          async client => await client.beta.threads.create()
        );
        setThread(newThread);
        await setCookie(newThread.id);
        if (
          window.Sofatutor.profileLevel ||
          window.Sofatutor.user.profile_type === 'parent'
        ) {
          await setClassLevel(newThread.id);
        }
        return newThread;
      }
    } catch (error) {
      Sentry.captureException(error);
      await params.injectMessage(
        <ErrorMessage message={t('sofabuddy_chat_bot.error_message')} />,
        'error'
      );
    }
  };

  const setClassLevel = async threadId => {
    try {
      await openAiWrapper.executeWithRetry(async client => {
        const profileLevel =
          window.Sofatutor.user.profile_type === 'parent'
            ? 6
            : window.Sofatutor.user.level;
        await client.beta.threads.messages.create(threadId, {
          role: 'assistant',
          content: `The Student you are teaching is in class level ${profileLevel}. Use this information to adjust your teaching style.`
        });

        const run = client.beta.threads.runs.stream(threadId, {
          assistant_id: assistantId
        });

        await run.finalRun();
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const sendMessage = async (params, thread) => {
    setUsageCounter(prevCounter => prevCounter - 1);
    !sofabuddyCookieExists && triggerSurvey();

    if (window.Sofatutor.env === 'test') {
      // For cypress tests, to simulate a message from the user (avoid using the OpenAI API)
      await params.streamMessage('This is a test message');
      return;
    }
    let text = '';

    try {
      await openAiWrapper.executeWithRetry(async client => {
        await client.beta.threads.messages.create(thread.id, {
          role: 'user',
          user: 'sofabuddy',
          content: params.userInput
        });
        const run = client.beta.threads.runs.stream(thread.id, {
          assistant_id: assistantId
        });

        run.on('textDelta', async delta => {
          text += delta.value || '';
          let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
          formattedText = formattedText.replace(/÷/g, ':');
          formattedText = formattedText.replace(/(\d)\s*x\s*(\d)/g, '$1 ⋅ $2');
          formattedText = formattedText.replace(/\bsqrt\b/g, '√');
          await params.streamMessage(formattedText);
        });

        await run.finalRun();

        const chatAnswerId = crypto.randomUUID();
        await trackConversation(
          params.userInput,
          text,
          thread.id,
          chatAnswerId
        );
        await params.injectMessage(
          <FeedbackMessage
            message={t('sofabuddy_chat_bot.feedback_message')}
            threadId={thread.id}
            chatAnswerId={chatAnswerId}
            question={params.userInput}
            answer={text}
          />,
          'feedback'
        );
        if (usageCounter - 1 <= 0) {
          disableChat();
        }
      });
    } catch (error) {
      Sentry.captureException(error);
      await params.injectMessage(
        <ErrorMessage message={t('sofabuddy_chat_bot.error_message')} />,
        'error'
      );
    }
  };

  const flow = {
    start: {
      component: () => initializeChat()
    },
    intro: {
      message: params => {
        params.injectMessage(
          <IntroMessage startChat={() => resumeChat()} />,
          'system'
        );
      }
    },
    welcome: {
      message: params => {
        params.injectMessage(
          <WelcomeMessage
            showSofabuddySurvey={showSofabuddySurvey}
            currentProfileId={currentProfileId}
            sofabuddyCookieExists={sofabuddyCookieExists}
            sofabuddySurveyClicked={sofabuddySurveyClicked}
          />
        );
      },
      path: 'loop'
    },
    loop: {
      message: async params => {
        if (!thread) {
          const createdThread = await retrieveOrCreateThread(params);
          if (createdThread) {
            await sendMessage(params, createdThread);
          }
        } else {
          await sendMessage(params, thread);
        }
      },
      path: () => 'loop'
    },
    end: {
      message: params => {
        params.injectMessage(
          <WarningMessage
            message={t('sofabuddy_chat_bot.warning_messages.usage_limit')}
          />,
          'warning'
        );
      },
      chatDisabled: true
    }
  };

  const trackConversation = async (
    question,
    answer,
    threadId,
    chatAnswerId
  ) => {
    try {
      const trackUrl = window.Sofatutor.Sofabuddy.trackUsageDataUrl;
      await makeRequest(trackUrl, 'POST', {
        question: question,
        answer: answer,
        chat_answer_id: chatAnswerId,
        thread_id: threadId
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const setStorageKey = () => {
    if (currentProfileId) {
      return currentProfileId;
    } else {
      return 'no_profile_id';
    }
  };

  const storageKey = useMemo(() => setStorageKey(), [currentProfileId]);

  const settings = useMemo(
    () =>
      chatbotSettings(
        t,
        closeChatIcon,
        sofabuddyCookieExists,
        sofabuddyThreadId,
        storageKey
      ),
    [t, closeChatIcon, sofabuddyCookieExists, sofabuddyThreadId, storageKey]
  );

  return <ChatBot styles={defaultStyles} settings={settings} flow={flow} />;
};

const SofabuddyChatBot = ({
  sofabuddyCookieExists,
  sofabuddyThreadId,
  currentProfileId,
  dailyRemainingSofabuddyMessages,
  showSofabuddySurvey,
  sofabuddySurveyClicked
}) => {
  return (
    /* This is a Context provider from the ChatBot library that provides the necessary hooks to manipulate messages & styles arrays for the ChatBot without re-rendering */
    <ChatBotProvider>
      {/* This is the ChatBot Wrapper component that renders the main ChatBot library component and also contains logic for the ChatBot */}
      <SofabuddyChatBotWrapper
        sofabuddyCookieExists={sofabuddyCookieExists}
        sofabuddyThreadId={sofabuddyThreadId}
        currentProfileId={currentProfileId}
        dailyRemainingSofabuddyMessages={dailyRemainingSofabuddyMessages}
        showSofabuddySurvey={showSofabuddySurvey}
        sofabuddySurveyClicked={sofabuddySurveyClicked}
      />
    </ChatBotProvider>
  );
};

export default SofabuddyChatBot;
